@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .no-more-tables tr { border: 1px solid #ccc; }

  .no-more-tables td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 25%;
    white-space: normal;
    text-align:left;
  }

  .no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 22%;
    padding-right: 10px;
    white-space: nowrap;
    text-align:left;
    font-weight: bold;
    font-size: 0.9em;
    padding-top: 0.55em;
  }

  /*
  Label the data
  */
  .no-more-tables td:before { content: attr(data-title); }
}